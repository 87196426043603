@mixin tag-styles {
  position: absolute;
  color: var(--color2);
  font-size: 20px;
  font-family: "La Belle Aurore", cursive;
}

.page {
  width: calc(100% - var(--sidebar-width));
  height: 100%;
  position: absolute;
  right: 0;
}

.tags {
  @include tag-styles;
  left: 50px;
}

.top-tag-body {
  top: 50px;
}

.bottom-tag-body {
  bottom: 50px;
}

.bottom-tag-html {
  bottom: 20px;
  margin-left: -25px;
}

.container {
  will-change: contents;
  width: 100%;
  height: 82%;
  min-height: fit-content;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s 1s forwards;

  .text-zone {
    position: absolute;
    inset: 50% 0 0 7.5%;
    transform: translateY(-50%);
    width: 40%;
    height: fit-content;
  }

  h1 {
    font: 400 85px/85px "Coolvetica";
    white-space: nowrap;

    &:before {
      content: "<h1>";
      @include tag-styles;
      left: -15px;
      margin-top: -40px;
      animation: fadeIn 1s 1s backwards;
    }

    &:after {
      content: "</h1>";
      @include tag-styles;
      margin-top: 30px;
      animation: fadeIn 1s 0.5s backwards;
    }
  }
}

// Mobile
@media screen and (max-width: 1200px) {
  .page {
    position: relative;
    width: 100%;
    min-height: calc((var(--vh, 1vh) * 100) - var(--sidebar-height));
    top: var(--sidebar-height);
  }

  .page.home {
    height: calc((var(--vh, 1vh) * 100) - var(--sidebar-height));
  }

  .page.show-nav {
    display: none;
  }

  .tags {
    font-size: 12px;
    left: 10px;
  }
  
  .top-tag-body {
    top: 2px;
  }
  
  .bottom-tag-body {
    bottom: 20px;
  }
  
  .bottom-tag-html {
    bottom: 2px;
    margin-left: -5px;
  }

  .container {
    position: initial;
    top: 0;
    height: 100%;
    transform: initial;
    padding: 15% 0 10% 0;

    .text-zone {
      position: relative;
      inset: initial;
      transform: initial;
      width: 90%;
      margin: 0 auto;
      //border: 1px solid white;
    }

    h1 {
      font: 400 60px/60px "Coolvetica";

      &:before {
        font-size: 12px;
        left: -15px;
        margin-top: -30px;
      }
  
      &:after {
        font-size: 12px;
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .container {
    padding: 7.5% 0 5% 0;
  }
  .page.home {
    height: fit-content;
  }
}
