#contact-container {
  .text-zone {
    width: 35%;
    vertical-align: middle;
    display: table-cell;
  }

  h1 {
    color: var(--color3);
  }

  p {
    padding: 15px 0 0 3px;
    font: 100 20px/30px "Coolvetica", sans-serif;
    color: var(--color5);
    letter-spacing: 0.5px;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-animate-hover {
    &:hover {
      color: var(--color4);
    }
  }

  .colored-letter {
    color: var(--color4);
  }

  .contact-colored-text {
    color: var(--color3);
  }

  .contact-form {
    width: 100%;
    margin-top: 25px;

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s forwards;
      }

      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      height: 50px;
      font: 100 20px/20px "Coolvetica", sans-serif;
      background: var(--color5);
      color: var(--color1);
      border: 4px solid var(--color4);
      border-radius: 10px;
    }

    input[type="text"],
    input[type="email"] {
      padding: 0 20px;
    }

    textarea {
      padding: 20px;
      min-height: 150px;
      resize: none;
      overflow: scroll;
    }

    .flat-button {
      border: 2px solid var(--color3);
      color: var(--color3);
      background: none;
      padding: 10px 25px 10px 30px;
      margin: 15px 0 0 2px;
      font: 100 15px/22px "Coolvetica", sans-serif;
      letter-spacing: 3px;
      text-decoration: none;
      text-align: center;

      &:hover {
        background: var(--color3);
        color: var(--color1);
      }
    }
  }

  .info-map {
    position: absolute;
    background: var(--color1);
    top: 5%;
    right: 2.5%;
    z-index: 999999;
    width: 225px;
    padding: 20px 0;
    color: var(--color5);
    font: 300 16px/24px "Helvetica";
    opacity: 0;
    animation: fadeIn 1s 1.5s forwards;
    text-align: center;
  }
}

// Mobile
@media screen and (max-width: 1200px) {
  #contact-container {
    .text-zone {
      padding: 0 2.5%;
    }

    h1 {
      &:before {
        left: 5%;
      }
    }

    p {
      font: 100 15px/22.5px "Coolvetica", sans-serif;
    }

    .contact-form {
      width: 100%;
      margin-top: 15px;

      input[type="text"],
      input[type="email"],
      textarea {
        font: 100 15px/15px "Coolvetica", sans-serif;
      }

      .flat-button {
        margin: 2px 0 20px 2px;
        font: 100 12px/18px "Coolvetica", sans-serif;
        border-radius: 0;
      }
    }

    .info-map {
      top: initial;
      margin-top: 11px;
      width: 160px;
      padding: 10px 0;
      font: 300 12px/18px "Helvetica";
    }
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  #contact-container {
    .text-zone {
      p {
        font: 100 10px/15px "Coolvetica", sans-serif;
      }
    }
  }
}
