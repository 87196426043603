.arrow {
  transform: scale(-1, 1);
  fill: var(--color3);
  flex: none;
  width: 50px;
}

@media screen and (max-width: 1200px) {
  .arrow {
    width: 33px;
  }
}
