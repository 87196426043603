.sbg-logo {
  width: 100%;
  position: absolute;
  inset: 0 0 0 0;
  margin: auto;

  .sbg-logo-letter,
  .sbg-logo-shadow {
    fill: none;
    stroke: var(--color5);
    stroke-width: 0.5;
  }
}
