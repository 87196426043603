$border-length: 3px;
$gap-size: 25px;

#portfolio-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $gap-size;

  h1 {
    color: var(--color5);
    text-align: center;
    margin-right: 30px;

    &:before {
      position: relative;
      top: -65px;
      left: initial;
    }
  }

  .port-colored-text {
    color: var(--color3);
  }

  .text-animate-hover {
    &:hover {
      color: var(--color3);
    }
  }
}

.images-container {
  display: flex;
  gap: $gap-size;

  &:first-child {
    margin-left: calc($gap-size + $border-length);
  }

  &:last-child {
    margin-right: calc($gap-size + $border-length);
  }

  .image-box {
    border: $border-length solid var(--color5);
    border-radius: 10px;
    position: relative;
    height: 400px;
    width: 400px;
    overflow: hidden;
    flex: 1 1 auto;
    justify-content: space-between;

    .portfolio-image {
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      z-index: 3;
      position: absolute;
      bottom: -70px;
      width: 100%;
      font: 100 20px/40px "Coolvetica", sans-serif;
      letter-spacing: 0.5px;
      padding: 10px 20px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .title {
      color: var(--color5);
      font-weight: 900;
      margin-bottom: -5px;
    }

    .description {
      color: var(--color3);
      font-weight: 700;
      font-size: 15px;
    }

    .btn {
      border: 2px solid var(--color5);
      border-radius: 10px;
      color: var(--color5);
      margin: 10px 0 20px 0;
      padding: 0 23px;
      height: 40px;
      background: transparent;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      color: var(--color3);
      background: var(--color5);
      border: 2px solid var(--color3);
      transform: translateY(-3px);
    }

    &:after {
      z-index: 2;
      opacity: 0;
      content: "";
      background: linear-gradient(180deg, var(--color3), #000);
      position: absolute;
      inset: 0 0;
      width: 100%;
      height: 100%;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:hover:after {
      opacity: 0.75;
    }

    &:hover .content {
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    }
  }
}

// Mobile
@media screen and (max-width: 1200px) {
  #portfolio-container {
    h1 {
      &:before {
        top: -55px;
      }
    }
  }

  .images-container {
    flex-wrap: wrap;
    margin-bottom: 5%;

    .image-box {
      height: 300px;
      width: 300px;

      .content {
        font: 100 18px/36px "Coolvetica", sans-serif;
      }

      .description {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  #portfolio-container {
    h1 {
      margin-top: -2%;
    }
  }
}
