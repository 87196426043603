#about-text-zone {
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  h1 {
    color: var(--color4);
  }

  .arrow-text-pair {
    display: flex;

    p {
      padding: 20px 0 0 33px;
      font: 100 20px/30px "Coolvetica", sans-serif;
      color: var(--color5);
      letter-spacing: 0.5px;

      animation: pulse 1.5s;
      &:nth-of-type(1) {
        animation-delay: 1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.5s;
      }
      &:nth-of-type(3) {
        animation-delay: 2s;
      }
    }
  }

  .text-animate-hover {
    &:hover {
      color: var(--color3);
    }
  }
}

.about-colored-text {
  color: var(--color3);
}

.cube-container {
  width: 50%;
  height: 75%;
  top: 7%;
  position: absolute;
  right: 0;
  padding-top: 7%;
  overflow: visible;

  #subtitle {
    width: 75%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font: 100 25px/30px "Coolvetica", sans-serif;
    letter-spacing: 0.5px;
    color: var(--color4);
  }

  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
      position: absolute;
      width: 300px;
      height: 300px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }

    .face1 {
      transform: translateZ(100px);
      color: #dd0031;
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }

  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateX(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }
}

@media screen and (max-width: 1200px) {
  #about-text-zone {
    .arrow-text-pair {
      p {
        padding: 40px 0 0 10px;
        font: 100 15px/22.5px "Coolvetica", sans-serif;
      }
    }
  }

  .cube-container {
    width: 100%;
    height: 320px;
    padding: 17.5% 0;
    position: relative;
    overflow: visible;

    #subtitle {
      font: 100 18px/22px "Coolvetica", sans-serif;
      padding-bottom: 5%;
    }

    .cubespinner {
      transform-origin: 100px 100px 0;

      div {
        width: 100px;
        height: 100px;
        font-size: 50px;
      }

      .face1 {
        transform: translateZ(50px);
      }
      .face2 {
        transform: rotateY(90deg) translateZ(50px);
      }
      .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(50px);
      }
      .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(50px);
      }
      .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(50px);
      }
      .face6 {
        transform: rotateX(-90deg) translateZ(50px);
      }
    }
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  #about-text-zone {
    .arrow-text-pair {
      p {
        font: 100 10px/15px "Coolvetica", sans-serif;
      }
    }
  }

  .cube-container {
    padding: 5% 0;
    #subtitle {
      font: 100 18px/22px "Coolvetica", sans-serif;
    }
  }
}
