$nav-color: var(--color3);
$icon-size: 37px;
$icon-color: var(--color1);
$icon-active-color: var(--color5);

@mixin icon-text($text) {
  &:after {
    content: $text;
  }
}

.nav-container,
nav,
.external-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 17px;

  svg {
    color: $icon-color;
  }
}

nav a,
.external-links a {
  height: 50px;
  font-size: $icon-size;
  color: $icon-color;
}

.nav-container {
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 3;
  height: 100%;
  min-height: fit-content;

  background: $nav-color;
  width: var(--sidebar-width);

  .logo {
    display: block;
    margin-top: 20px;

    img {
      display: block;
      margin: 0 auto;
      width: 85%;

      &.logo-subtitle {
        margin-top: 2px;
      }
    }
  }

  nav {
    position: absolute;
    inset: 50% 0 0 50%;
    transform: translate(-50%, -50%);
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font: bold $icon-size sans-serif;
      line-height: 50px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.33s ease-out;
      }

      &:hover {
        color: $icon-active-color;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: "";
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
      }

      &:first-child {
        @include icon-text("HOME");
      }
    }

    a.about-link {
      @include icon-text("ABOUT");
    }

    a.projects-link {
      @include icon-text("PROJECTS");
    }

    a.contact-link {
      @include icon-text("CONTACT");
    }

    a.active {
      svg {
        color: $icon-active-color;
      }
    }
  }

  .external-links {
    align-items: center;
    margin-bottom: 20px;

    a {
      line-height: 50px;

      &:hover {
        .anchor-icon {
          color: $icon-active-color;
        }
      }
    }
  }
}

.close-icon,
#burgermenu-svg,
#sidebar-bg-container {
  display: none;
}

// Mobile
@media screen and (max-width: 1200px) {
  .nav-container {
    position: fixed;
    width: 100vw;
    height: var(--sidebar-height);

    nav,
    .external-links {
      display: none;
    }

    .logo {
      height: 100%;
      margin-top: initial;

      img {
        position: absolute;
        top: 6px;
        left: 6px;
        height: 80%;
        width: auto;

        &.logo-subtitle {
          display: none;
        }
      }
    }

    #burgermenu-svg {
      display: block;
    }
  }

  .nav-container.show-nav-page {
    position: absolute;
    height: calc((var(--vh, 1vh) * 100));

    .logo,
    #burgermenu-svg {
      display: none;
    }

    #sidebar-bg-container {
      display: block;
    }

    .close-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 2px;
      width: 50px;
      height: 7.5%;
      color: white;
    }

    nav,
    .external-links {
      background-color: transparent !important;
      position: absolute;
      display: flex;
      width: 100%;
    }

    nav {
      top: 33%;
      height: 40%;
      justify-content: space-between;
      background: var(--color3);
    }

    .external-links {
      bottom: 25%;
      justify-content: space-evenly;
      flex-direction: row;
      align-items: start;
      background: var(--color3);
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a:after {
      opacity: 1;
      position: initial;
      width: auto;
      margin-left: 10px;
    }

    a.active {
      color: var(--color5);
    }

    a svg {
      opacity: 1 !important;
    }
  }
}
