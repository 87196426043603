.home-container {
  h1 {
    color: var(--color5);

    &:after {
      margin-top: initial;
    }

    img {
      margin: 0 0 -25px 20px;
      width: 110px;
      animation: rotateIn 0.25s linear 2.75s both;
    }
  }

  h2 {
    color: var(--color3);
    margin-top: 20px;
    font: 400 15px sans-serif;
    letter-spacing: 3px;
    white-space: nowrap;
    animation: fadeIn 1.5s 1.8s backwards;
  }

  .flat-button {
    border: 1px solid var(--color4);
    padding: 10px 18px;
    margin-top: 25px;
    color: var(--color4);
    font: 400 15px sans-serif;
    letter-spacing: 4px;
    text-decoration: none;
    display: block;
    width: fit-content;
    white-space: nowrap;
    animation: fadeInAnimation 1s 1.8s backwards;

    &:hover {
      background: var(--color4);
      color: var(--color1);
    }
  }

  #colored-letter-home {
    color: var(--color4);
  }

  #job-break {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .home-container {
    .text-zone {
      width: 80%;
      top: 10%;
    }

    h1 img {
      margin: 0 0 -18px 10px;
      width: 77px;
    }

    h2 {
      font: 400 12px sans-serif;
      white-space: wrap;
      width: 100%;
      letter-spacing: 2px;
    }

    .flat-button {
      font: 400 12px sans-serif;
      letter-spacing: 2.5px;
      border-width: 2px;
    }

    #job-break {
      display: initial;
    }
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .home-container {
    padding: 5% 0;
  }
}
