@mixin draw($color){
  to {
    stroke: $color;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }
}

#logo-svg {
  width: 750px;
  position: absolute;
  top: 5%;
  right: 7.5%;
  bottom: 0;
  margin: auto;
  z-index: -1;
}

#letter,
#shadow {
  stroke-dasharray: 30;
  stroke-dashoffset: 450;
  fill: none;
  stroke: var(--color1);
  stroke-width: 1;
}

#letter {
  animation: drawLetter 5.5s linear forwards;
}

#shadow {
  animation: drawShadow 5s linear forwards;
}

@keyframes drawLetter {
  @include draw(var(--color3));
}

@keyframes drawShadow {
  @include draw(var(--color4));
}

// Mobile
@media screen and (max-width: 1200px) and (orientation: portrait) {
  #logo-svg {
    display: none;
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  #logo-svg {
    overflow-x: hidden;
    width: 50%;
  }
}
